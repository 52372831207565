.ant-table-tbody .row-hidden-actions {
  opacity: 0;
}

.ant-table-tbody tr:hover > td > .row-hidden-actions {
  opacity: 1;
}

.ant-table-tbody tr:hover > td.row-actions {
  vertical-align: top;
}

.row-dragging td.row-actions {
  vertical-align: top;
}
