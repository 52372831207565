.gjs-pn-devices-c {
  position: relative;
}

.gjs-pn-gt {
  left: 120px;
}

.gjs-pn-gt__button-title {
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
