.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: table;
  width: 100%;
  text-align: left;
}

.row-dragging .ant-table-cell-fix-right {
  display: none;
}

.row-dragging td {
  padding: 16px;
  vertical-align: middle;
}

.row-dragging .drag-visible {
  visibility: visible;
}
